.App {
    text-align: center;
    /* background-color: #00f09c; */
    background-color: #ffffff;
    color: #333333;
    min-height: 100vh;
    overflow: hidden;
  }
  
  .App-header {
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
  }

  .App-link {
    color: #61dafb;
  }
  